import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

export default function Evento(props) {
  return (
    <Paper
      sx={{
        p: 2,
        margin: "auto",
        maxWidth: 500,
        flexGrow: 1,
        backgroundColor: "rgb(255 249 124)",
        borderRadius: "32px",
        boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
        textAlign: "left",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6">{props.date}</Typography>
          <Typography variant="h6">{props.time}</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography gutterBottom variant="h6" component="div">
            {props.title}
          </Typography>
          <Typography variant="body2">{props.text}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
