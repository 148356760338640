import styles from "../styles.css";
import { Typography } from "@mui/material";
import PageWrapper from "../componenti/PageWrapper";
import Evento from "../componenti/Evento";

export default function News() {
  return (
    <PageWrapper>
      <Typography variant={"h4"} align={"center"}>
        NEWS
      </Typography>
      <span className="separatorLarge" />
      <Evento
        time={"ore 20.30"}
        date={"28 Marzo 2025"}
        title={
          "DIMENSIONI ALTRE"
        }
        text={
          <Typography variant="body2">
            Spazio Yoga MAHA DEVI propone "DIMENSIONI ALTRE" INCONTRO CON FRANCY GUAGLIARDI. <br /> guarda <a href="../images/eventi/dimensioni.jpeg" target="_blank">qui</a> il volantino con tutte le informazioni.
            <br /><br />
            Per info e prenotazione:
            Laura Bellù <a href="tel:+393341627843">3341627843</a>
          </Typography>
        }
      />
      <span className="separatorSmall" />
      <Evento
        // time={"ore 20.30"}
        date={"27-28-29 Giugno 2025"}
        title={
          "VACANZA YOGA"
        }
        text={
          <Typography variant="body2">
            Spazio Yoga MAHA DEVI vi invita a VACANZA YOGA a CANNERO RIVIERA (VB) dal 27 al 29 Giugno 2025. <br /> guarda <a href="../images/eventi/vacanza25.jpeg" target="_blank">qui</a> il volantino con tutte le informazioni.
            <br /><br />
            Per info e prenotazione:
            Laura Bellù <a href="tel:+393341627843">3341627843</a>
          </Typography>
        }
      />
      <span className="separatorSmall" />
    </PageWrapper>
  );
}
