import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export default function CardComponent(props) {
  return (
    <Card sx={{
      backgroundColor: "#f7f7f7",
      borderRadius: "32px",
      boxShadow:
        "0px 0px 6px 2px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    }}
    >

      {props.image && (
        <CardMedia component="img" alt="" height="350" image={props.image} />
      )}
      <CardContent>
        {props.title && (
          <Typography gutterBottom variant={"h4"} component="div">
            {props.title}
          </Typography>
        )}
        {props.text && (
          <Typography variant="body2" color="text.secondary">
            {props.text}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
