import Grid from "@mui/material/Grid";
import StickyNote from "./StickyNote"; // Importa il componente

export default function TabellaOrari() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <StickyNote title="Lunedi" content="18.15 - 19.15" />
      </Grid>
      <Grid item xs={12} md={3}>
        <StickyNote title="Martedi" content={"18.00 - 19.00\n19.30 - 20.30"} />
      </Grid>
      <Grid item xs={12} md={3}>
        <StickyNote title="Mercoledi" content={"9.15 - 10.15\n19.30 - 20.30"} />
      </Grid>
      <Grid item xs={12} md={3}>
        <StickyNote title="Giovedi" content={"9.15 - 10.15\n18.15 - 19.15"} />
      </Grid>
    </Grid>
  );
}
