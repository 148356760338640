import * as React from "react";
import PageWrapper from "../componenti/PageWrapper";
import Album from "../componenti/Album";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Grid';

// Funzione per caricare le immagini
const importImages = (context) => context.keys().map(context);

export default function Galleria() {
  // Dati degli album con immagini e titoli
  const albums = [
    { title: "Foto varie", images: importImages(require.context("../images/fotovarie/", false, /\.(png|jpe?g|svg)$/)) },
    { title: "Cannero Riviera 2019", images: importImages(require.context("../images/canneroRiviera2019/", false, /\.(png|jpe?g|svg)$/)) },
    { title: "Cannero Riviera 2017", images: importImages(require.context("../images/canneroRiviera2017/", false, /\.(png|jpe?g|svg)$/)) },
  ];

  const [selectedAlbum, setSelectedAlbum] = React.useState(null);

  const handleAlbumClick = (albumTitle) => {
    setSelectedAlbum(selectedAlbum === albumTitle ? null : albumTitle);  // Toggle album visibility
  };

  return (
    <PageWrapper>
      <Typography variant={"h4"} align={"center"} gutterBottom>
        GALLERIA
      </Typography>
      <span className="separatorLarge" />
      <Grid container spacing={4}>
        {albums.map((album) => (
          <Grid item xs={12} key={album.title}>
            <Album
              itemData={album.images}
              title={album.title}
              isOpen={selectedAlbum === album.title}
              onClick={() => handleAlbumClick(album.title)}
            />
          </Grid>
        ))}
      </Grid>
    </PageWrapper>
  );
}
