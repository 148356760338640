import React, { useState, memo } from 'react';
import { Modal, Box, ImageList, ImageListItem, Card, CardContent, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Modificato per non calcolare costantemente gli stessi valori
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  padding: "0",
  width: "100vw",
  height: "100vh",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Album = ({ itemData, title, isOpen, onClick }) => {
  const [modal, setModal] = useState({ isOpen: false, imageSrc: "" });

  // Evita il rendering se itemData è vuoto
  if (!itemData?.length) return null;

  const openModal = (imageSrc) => setModal({ isOpen: true, imageSrc });
  const closeModal = () => setModal({ isOpen: false, imageSrc: "" });

  return (
    <React.Fragment>
      <Card sx={{
        backgroundColor: "#f7f7f7",
        borderRadius: "32px",
        boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
        overflow: "hidden"
      }}>
        <div
          style={{
            position: "relative",
            height: 250,
            backgroundImage: `url(${itemData[0]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            cursor: "pointer",
          }}
          onClick={onClick}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#fff",
              fontWeight: "bold",
              fontSize: "2rem",
              textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
              textAlign: "center",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              padding: "10px 20px",
              borderRadius: "10px",
            }}
          >
            {title}
          </div>
        </div>
        <CardContent />
      </Card>

      {isOpen && (
        <ImageList variant="masonry" cols={3} gap={8} style={{ marginTop: 20 }}>
          {itemData.map((imageSrc, index) => (
            <ImageListItem key={index}>
              <img
                src={imageSrc}
                alt={`photo-${index}`}
                onClick={() => openModal(imageSrc)}
                style={{
                  width: "100%",
                  height: "auto",
                  cursor: "pointer",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}

      {/* Modal per immagine ingrandita */}
      <Modal
        open={modal.isOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={modalStyle}
          onClick={closeModal} // Close when clicking on the background
        >
          <CloseIcon
            onClick={(e) => {
              e.stopPropagation(); // Prevent the modal from closing when clicking on the icon
              closeModal();
            }}
            style={{
              position: "absolute",
              top: 20,
              right: 20,
              color: "#fff",
              fontSize: "2rem",
              cursor: "pointer",
              zIndex: 10,
            }}
          />
          <img
            src={modal.imageSrc}
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
              margin: "auto",
              cursor: "pointer",
            }}
            onClick={(e) => e.stopPropagation()} // Prevent the modal from closing when clicking the image
          />
        </Box>
      </Modal>
    </React.Fragment>
  );
};

// Memorizziamo il componente per evitare il rendering inutile
export default memo(Album);