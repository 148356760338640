import PageWrapper from "../componenti/PageWrapper";
import { Typography } from "@mui/material";
import Evento from "../componenti/Evento";

export default function Passati() {
  return (
    <PageWrapper>
      <Typography variant={"h4"} align={"center"}>
        EVENTI PASSATI
      </Typography>

      <span className="separatorLarge" />
      <Evento
        // time={"ore 9:30"}
        date={"23 Settembre 2024"}
        title={
          "Corsi di yoga"
        }
        text={
          <Typography variant="body2">
            I Corsi di yoga riprenderanno Lunedi 23 settembre, guarda <a href="../images/eventi/corsi24.jpeg" target="_blank">qui</a> il volantino con orari e novità
            <br /><br />
            Per info e prenotazione:
            Laura Bellù 3341627843
          </Typography>
        }
      />
      <span className="separatorSmall" />
      <Evento
        time={"ore 9:30"}
        date={"15 Giugno 2024"}
        title={
          "Yoga all'aperto"
        }
        text={
          <Typography variant="body2">
            Spazio yoga "Maha Devi" propone YOGA ALL' APERTO con picnic. Guarda <a href="../images/eventi/aperto.jpeg" target="_blank">QUI</a>
            <br /><br />
            Pratica yoga aperta a tutti.
            SABATO 15 GIUGNO
            Ore 9.30/13.00
            Ritrovo in sede, in via Lodorina 20, ad Erba
            alle ore 9.30
            Portare tappetino, picnic e un sorriso🙂.
            <br />
            Per info e prenotazione:
            Laura Bellù :334 1627843
            <br />
            (In caso di maltempo  la pratica si svolgerà in sede)<br />
          </Typography>
        }
      />
      <span className="separatorSmall" />
      <Evento
        time={"ore 20.45"}
        date={"6 Giugno 2024"}
        title={
          "BAGNO DI GONG"
        }
        text={
          <Typography variant="body2">
            Bagno di gong con il dott. Fausto Radaelli, master gong. Guarda <a href="../images/eventi/gong062024.jpeg" target="_blank">QUI</a>
            <br /><br />
            GONG, CAMPANE, CIOTOLE INDIANE E ALTRI STRUMENTI DI ANTICA TRADIZIONE
            formano un concerto di suoni unici e avvolgenti le cui benefiche vibrazioni
            pervadono ogni cellula del corpo pulendo, armonizzando e nutrendo.
            La mente si depone sui suoni e il confine tra conscio e inconscio
            si assottiglia...una esperienza intensa da vivere nella quiete di un
            ambiente accogliente...<br />
            <br />
            Chiamare per informazioni e iscrizioni. <br />
          </Typography>
        }
      />
      <span className="separatorSmall" />
      <Evento
        time={"ore 20.45"}
        date={"9 Febbraio 2024"}
        title={
          "BAGNO DI GONG"
        }
        text={
          <Typography variant="body2">
            Con Fausto Radaelli. Presso la sede ad Erba, in via Lodorina 20. <br />
            Per iscrizioni contattare Laura Bellú. <br />
            Nella danza della vita il Tutto si fa movimento, vibrazione, frequenza. Tutto è frequenza vibratoria che nasce e si riassorbe in un silenzio che è pienezza. Nella tranquillità di un ambiente accogliente, nell'ascolto, lasciamoci pervadere dalle frequenze generate nel concerto di gong e campane. Alcune frequenze entreranno in risonanza con le frequenze dei nostri organi, delle nostre cellule e potranno rivelare e sciogliere nodi, rilassare, riequilibrare, rigenerare.. buon ascolto, buon cammino!
          </Typography>
        }
      />
      <span className="separatorSmall" />
      <Evento
        time={""}
        date={"17 Settembre 2023"}
        title={
          "FESTA DELL'ARMONIA"
        }
        text={
          <Typography variant="body2">
            Domenica 17 settembre si svolgerà presso l'azienda agricola Grattarola via cornizzolo 56 a Eupilio la FESTA DELL'ARMONIA, promossa da alcune realtà locali, per incontrarsi e vivere momenti gioiosi insieme.
            Per la partecipazione agli eventi si richiede un contributo e l'intero incasso sarà devoluto alla scuola di tessitura "Espace Tissage" del Benin per ragazze in difficoltà e ad un agricoltore emiliano alluvionato. <br />
            Per la pratica di yoga ed il bagno di gong è veramente gradita la prenotazione. <br />
            A mezzogiorno è possibile per i partecipanti alla festa un pic-nic nel prato. <br />
            I posteggi non sono molti e consigliamo di ottimizzare gli spazi nelle vetture. <br />
            Clicca <a href="../images/eventi/armonia.jpeg" download >QUI</a> per vedere il volantino dell'evento. <br />
            Vi aspettiamo! <br />
            Laura Bellù
          </Typography>
        }
      />
      <span className="separatorSmall" />
      <Evento
        time={"ore 20.30"}
        date={"20 Aprile 2023"}
        title={
          "MOVIMENTO, RESPIRO, CANTO DELLA OM E MEDITAZIONE DEL CUORE PER LA PACE"
        }
        text={
          <Typography variant="body2">
            Accompagnati dai suoni delle campane tibetane, con Laura. <br />
            Presso la sede di via Lodorina 20 a Erba.
          </Typography>
        }
      />
      <span className="separatorSmall" />
      <Evento
        time={"ore 19.30 - 22.30"}
        date={"4 Maggio 2023"}
        title={"USO E SEGRETI DELLE CAMPANE TIBETANE"}
        text={
          <Typography variant="body2">
            Lezione a cura di Fausto Redaelli - Master Gong e Psicoterapeuta.
            <br />
            Presso la sede di via Lodorina 20 a Erba.
          </Typography>
        }
      />
      <span className="separatorSmall" />
      <Evento
        time={"ore 20.45"}
        date={"18 Maggio 2023"}
        title={"BAGNO DI GONG"}
        text={
          <Typography variant="body2">
            Bagno di Gong con Fausto Redaelli - Master Gong e Psicoterapeuta.
            <br />
            Presso la sede di via Lodorina 20 a Erba.
          </Typography>
        }
      />
      <span className="separatorSmall" />
      <Evento
        time={"(Luogo e data da definire)"}
        date={"Giugno 2023"}
        title={"GIORNATA DI YOGA EN PLEIN AIR"}
        text={
          <Typography variant="body2">
            Con Laura
            <br />
            Presso la sede di via Lodorina 20 a Erba.
          </Typography>
        }
      />
      <span className="separatorSmall" />
      <Evento
        date={"14 Febbraio 2022"}
        title={"INCONTRI DI YOGA"}
        text={
          <Typography variant="body2">
            Gli incontri yoga sono momentaneamente sospesi, ma riprenderanno il
            14 Febbraio sia in presenza per chi vorrà, sia online.
            <br />
            Chi desidera può prenotarsi fin da ora.
            <br />
            Un augurio di serenità. <br />
            Laura
          </Typography>
        }
      />
      <span className="separatorSmall" />
      <Evento
        title={"INCONTRI IN PRESENZA"}
        text={
          "Sono iniziate le pratiche in presenza che si svolgeranno all’aperto fino a fine Giugno, con yoga nel prato, se il tempo lo permette.Consultare la pagina Orari"
        }
      />
      <span className="separatorSmall" />
      <Evento
        time={"ore 9.15"}
        date={"12 Giugno 2021"}
        title={"YOGA NEL PRATO"}
        text={
          "Ritrovo presso “Spazio Yoga Maha Devi” alle ore 9.15.\n" +
          "Ci possiamo fermare per un pranzo al sacco.\n" +
          "L’incontro sarà possibile solo con il bel tempo.\n" +
          "Sarà bello ritrovarsi..\n" +
          "La quota è di 15€ comprensiva di spuntino di mezza mattina.\n" +
          "Ai partecipanti fornirò indicazioni più precise.\n" +
          "Per iscriversi basta un messaggio.\n" +
          "Un gioioso saluto!\n" +
          "Laura"
        }
      />
      <span className="separatorSmall" />
      <Evento
        time={"ore 20.30"}
        date={"29 Novembre 2019"}
        title={"BAGNO DI GONG"}
        text={
          "con Fausto Radaelli, psicoterapeuta e master gong nella sede di erba."
        }
      />
      <span className="separatorSmall" />
      <Evento
        time={"ore 9.45 – 12.00"}
        date={"9 Novembre 2019"}
        title={"IL CHAKRA DEL CUORE"}
        text={"Incontro teorico pratico con Laura nella sede di erba."}
      />
      <span className="separatorSmall" />
      <Evento
        time={"ore 20.30"}
        date={"25 Ottobre 2019"}
        title={"DANZE MEDITATIVE IN CERCHIO"}
        text={"Con la danzaterapeuta Betty Ottolina nella sede di erba."}
      />
      <span className="separatorSmall" />
      <Evento
        time={"ore 19.00"}
        date={"19 o 25 Settembre 2019"}
        title={"PRATICHE GRATUITE PER PROVARE"}
        text={
          "Per chi desidera provare prima di iniziare, sono previste 2 prove gratuite: Si può scegliere tra giovedì 19 o mercoledì 25 settembre alle ore 19.00, nella sede di erba.\n" +
          "\nE’ necessario prenotarsi al 334 1627843."
        }
      />
      <span className="separatorSmall" />
      <Evento
        time={"ore 19.00"}
        date={"18 e 26 Settembre 2019"}
        title={"PRATICHE DI RODAGGIO"}
        text={
          "Per chi vuole ricominciare presto e dolcemente ecco 2 pratiche settembrine: Mercoledì 18 e Giovedì 26 settembre, alle ore 19.00 nella sede di erba."
        }
      />
      <span className="separatorSmall" />
      <Evento
        time={"ore 11.00"}
        date={"22 Settembre 2019"}
        title={"PRATICA YOGA APERTA A TUTTI"}
        text={
          "Presso l’Oasi di S.Maria degli angeli a Erba, in via Clerici 7, nell’ambito della feste delle api (www.festadelleapi.it).\nIscrizioni in loco, gradita la prenotazione.\nPortare il tappettino.\n" +
          "\n"
        }
      />
      <span className="separatorSmall" />
      <Evento
        date={"Dal 4 al 7 luglio 2019"}
        title={"VACANZA YOGA A CANNERO RIVIERA"}
        text={"Ulteriori dettagli in seguito."}
      />
      <span className="separatorSmall" />
      <Evento
        date={"10 maggio 2019"}
        title={"OSSERVIAMO LE STELLE "}
        text={
          "Ritrovo presso la sede di Erba, poi ci recheremo insieme all’osservatorio astronomico di Sormano. Orario e ulteriori dettagli in seguito.\n"
        }
      />
    </PageWrapper>
  );
}
