import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import './fonts/NothingYouCouldDo-Regular.ttf';
import ReactGA from "react-ga4";
import Cookies from 'universal-cookie';
import CookieConsent from "react-cookie-consent";
import GlobalStyles from "@mui/material/GlobalStyles";

/**siccome google analytics usa cookie devo mostrare banner, quando utente accetta setto un cookie che poi leggo per sapere
se è gia stato accettato. se è accettato inizializzo il token di google
*/
const cookies = new Cookies();
if (cookies.get('accept-cookies')) {
  ReactGA.initialize('G-3ZGZQBJTRV');
}

const handleAccept = () => {
  ReactGA.initialize('G-3ZGZQBJTRV');
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <GlobalStyles
      styles={{
        body: {
          //  backgroundImage: `url(${rootBackground})` 
          background: 'linear-gradient(180deg, #fffefd, #faddff)',
          backgroundSize: '200% 200%',
          animation: 'animateBackground 10s infinite alternate',
        }
      }}
    />
    <App />
    <CookieConsent
      location="bottom"
      buttonText="Ho capito"
      cookieName="accept-cookies"
      onAccept={handleAccept}
    >
      Questo sito usa i cookie per analizzare i flussi di navigazione attraverso Google analytics, niente di più.
    </CookieConsent>
  </ThemeProvider>,
  document.querySelector('#root'),
);
