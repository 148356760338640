import Grid from "@mui/material/Grid";
import StickyNote from "./StickyNote"; // Importa il componente riutilizzabile

export default function TabellaOrari() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} md={3}>
        <StickyNote title="Lunedi" content="19.30 - 20.30" />
      </Grid>
    </Grid>
  );
}

