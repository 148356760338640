import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function StickyNote({ title, content }) {
  return (
    <Card
      sx={{
        backgroundColor: "rgb(255 249 124)",
        borderRadius: "32px",
        boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
        textAlign: "center",
        padding: 2,
      }}
    >
      <CardContent>
        <Typography variant="h6" align="center">
          {title}
        </Typography>
        <span className="separatorSmall" />
        {/* Separa le righe del contenuto e le stampa con <br /> */}
        <Typography>
          {content.split("\n").map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </Typography>
      </CardContent>
    </Card>
  );
}
